.parentlist {
    width: 30%;
    /* height: 405px; */
    /* height: auto; */
    height: 340px;
    padding: 5px;
    background-color: #fff;
    margin: 0 20px 20px 0;
    box-shadow: 0px 0px 5px 0px #a3a1a1;
    overflow: auto;
    overflow-x: hidden;
}

.parentlist h3 {
    height: 16px;
    overflow: hidden;
}

.parentlist>div>div :nth-child(2) {
    height: 16px;
    overflow: hidden;
}

.parentlist>div>div :nth-child(3) {
    height: 16px;
    overflow: hidden;
}

.eyeicn {
    height: 36px !important;
}

.list {
    width: 100%;
    height: auto;
    margin: -4px;
}

.left {
    display: flex;
    gap: 0.7rem;
    flex-direction: column;
    /* background-color: aquamarine; */
    /* flex: 3 1; */
    padding: 9px 15px;
}

.left span {
    font-size: 14px;
    font-weight: bold;
    word-break: break-word;
}

.left p {
    float: left;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* flex: 2; */
}

.right a {
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    background-color: rgb(3, 201, 215);
    color: white;
    border-radius: 4px;
}


@media (max-width: 768px) {
    .list {
        flex-wrap: wrap;
    }

    .left {
        flex: auto;
        width: 100%;
    }

    .right {
        padding: 10px 0;
    }

    .parentlist {
        width: 45%;
    }
}

@media (max-width: 576px) {
    .parentlist {
        width: 100%;
    }
}