.Main {
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    background-color: rgb(246, 247, 240);
    gap: 2rem;
}


.leftsection {
    flex: 2;
    display: flex;
    /* justify-content: space-between; */
}

.topleft {
    flex: 3;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.topright {
    flex: 2;
    float: left;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.rightsection {
    flex: 2;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    justify-content: space-between;
}

span {
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.cvdetails {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

}

.cvdetails .btn {
    text-decoration: underline;
    font-size: 22px;
    line-height: normal;
    color: black;
    padding: 0;
    margin-bottom: 10px;
    box-shadow: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.right_leftsde {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#imageone {
    /* margin-left: 60px;
    position: absolute;
    z-index: 1; */
    border-radius: 10px;
}

#imagetwo {
    position: absolute;
    z-index: 5;
    margin-left: 40px;
    border-radius: 10px;
    /* float: right;
    margin-right: 14rem; */
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
}

.cvimage {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2%;
}

.cvimgown {
    width: 80px;
    height: 75px;
}

.cvimage>div:nth-of-type(2) {
    display: none;
}

.cvimage>div:nth-of-type(3) {
    display: none;
}

.cvimage>div:nth-of-type(4) {
    display: none;
}

.cvimgown img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

}

/* .cvimage {} */
.imgShadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.oneDiv {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
    margin-bottom: 20px;
    height: 325px;
    overflow-y: auto;
}

.wrapper {
    display: grid;
    padding: 10px;

    grid-template-columns: 1fr 2fr 1fr;
    gap: 10px;
}

.box {
    box-sizing: border-box;
    padding: 10px;
}

@media screen and (max-width: 600px) {
    .wrapper {
        grid-template-columns: 1fr;
    }

    .leftsection {
        flex-wrap: wrap;
    }

    .topleft {
        flex: auto;
        width: 100%;
    }

    .topright {
        flex: auto;
        width: 100%;
    }

    .rightsection {
        flex: auto;
        width: 100%;
    }

    .right_leftsde {
        display: flex;
        justify-content: start;
        align-items: center;
    }
}

.leftContent {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.leftContent,
h3 {
    font-weight: 800;
}

.leftContent,
h4 {

    font-weight: 600;
}

.selectInput {
    /* border: 1px solid red; */
    font-size: 20px;
    height: 10px;
    /* padding: 20px; */
}


/* .datePicker{
    margin-left: 10rem;

} */
.rdrDefinedRangesWrapper {
    display: none;
}

.filterDiv {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 42px;
    margin-bottom: 30px;
}

.filterDiv>div:nth-of-type(3) {
    display: flex;
    gap: 10px;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.calenderpacakgediv {
    position: relative;
}

.datepickerdivcalender {
    position: absolute;
    left: 60%;
    top: 171px;
    z-index: 99;
}

.datepickerdivcalender .rdrDateRangePickerWrapper {
    box-shadow: 0px 0px 5px 0px #ccc;
    border: 1px solid #ccc;
}




/******filterdropbox*******/

.filterdropbox {
    width: 350px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1;
    transition: 0.5s;
}

.filterdropbox.active {
    right: 0;
}

.filterdrop_body {
    width: 100%;
    height: 500px;
    overflow-y: auto;
    background-color: #fff;
    padding: 40px;
    color: #000;
}

.filterdrop_body .closebtn {
   position: absolute;
   top: 15px;
   right: 15px;
   z-index: 2;
}

.filterdrop_body .headline {
   font-size: 22px;
   font-weight: 700;
   text-align: left;
   padding: 0;
   margin-bottom: 20px;
}